<template>
    <div id="menu-actions-component">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" @click="bottom_sheet = true">
                    <slot name="icon">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </slot>
                </div>
            </template>

            <v-list v-if="!is_mobile">
                <template v-for="action in actions">
                    <v-list-item  @click="$emit(action.emit)" :data-cy="action.emit" v-if="action.has_access" :key="action.emit">
                        <v-list-item-icon class="my-auto mr-6">
                            <v-icon :color="`${action.color}`">{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="my-0">
                            <span :class="`${action.color}--text`">{{ action.title }}</span>
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>

        <v-bottom-sheet v-if="is_mobile" v-model="bottom_sheet">
            <v-sheet v-touch:swipe.bottom="swipeBottomHandler">
                <v-list>
                    <template v-for="action in actions">
                        <v-list-item  @click="$emit(action.emit)" :data-cy="action.emit" v-if="action.has_access" :key="action.emit">
                            <v-list-item-icon class="my-auto mr-6">
                                <v-icon :color="`${action.color}`">{{ action.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="my-0">
                                <span :class="`${action.color}--text`">{{ action.title }}</span>
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
export default {
    name: 'MenuActionsComponent',
    props: {
        actions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            bottom_sheet: false
        }
    },
    methods: {
        swipeBottomHandler() {
            this.bottom_sheet = false
        }
    }
}
</script>
